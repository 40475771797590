@font-face {
  font-family:FuturaPT;
  font-weight: 400;
  src: url(./assets/font/FuturaPTMedium.otf);
}
@font-face {
  font-family:FuturaPT;
  font-weight: 700;
  src: url(./assets/font/FuturaPTBold.otf);
}
@font-face {
  font-family:FuturaPT;
  font-weight: 600;
  src: url(./assets/font/FuturaPTHeavy.otf);
}
@font-face {
  font-family:FuturaPT;
  font-weight: 500;
  src: url(./assets/font/FuturaPTDemi.otf);
}
@font-face {
  font-family:FuturaPT;
  font-weight: 800;
  src: url(./assets/font/FuturaPTExtraBold.otf);
}
@font-face {
  font-family:baloo;
  src: url(./assets/font/Baloo-Regular.ttf);
}

*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

html, body {
  min-height: 100%;
  margin: 0;
  padding: 0;
} 

body {
  margin: 0;
  font-family: FuturaPT, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #1E2538;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* =============== Typography =============== */
sup, sub {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup { top: -0.5em; }
sub { bottom: -0.25em; }

/* Headings */
h1, h2, h3, h4, h5, h6 {
  line-height: normal;
}

h1 {
  font-family: baloo, sans-serif;
  font-size: 78px;
  font-weight: normal;
  color: #47525E;
}
h2 {
  font-family: baloo, sans-serif;
  font-size: 48px;
  font-weight: normal;
  color: #47525E;
}
h3 {
  font-family: baloo, sans-serif;
  font-size: 40px;
  font-weight: normal;
  color: #47525E;
}
h4 {
  font-family: baloo, sans-serif;
  font-size: 36px;
  font-weight: normal;
  color: #47525E;
}
h5 {
  font-family: FuturaPT, sans-serif;
  font-size: 36px;
  color: #47525e;
}
h6{
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}
a{
  color: #23A6D7;
  text-decoration: none;
}

/* Horizontal Rules */
hr {
  color: #ccc;
  background-color: #dee2e6;
  height: 1px;
  border: none;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}




/* login start */



.login_wrapper h6 {
  font-size: 28px;
  font-family: baloo;
  margin: 20px 0;
}

/*.form-control {*/
/*  font-size: 20px;*/
/*  background: #ECEDEE;*/
/*  border-color: #ECEDEE;*/
/*  padding: 10px 20px;*/
/*  height: auto;*/
/*  color: #6c757d;*/
/*}*/

.login_wrapper .btn {
  width: 100%;
  font-size: 20px;
  background: #47525E;
  border-color: #47525E;
  padding: 10px 20px;
}
.login_wrapper .forgot_wrapper {
  font-size: 16px;
  color: #47525E;
  display: flex;
  justify-content: space-between;
}

.login_wrapper .forgot_wrapper small {
  font-size: 100%;
}


.body_container_wrapper.login_page {
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 10%;

}

html, body {
  height: 100%;

}
.login_bg{
  height: 100%;background-image: url(./assets/img/background.png);
  background-size: cover;}
/* .body_container_wrapper.login_page {
  background-repeat: no-repeat;
  background-position: center bottom -30px;
  background-size: contain;
} */


/* login end */
.login_wrapper .error {
  text-align: left;
  color: #fff;
}

.body_container {
  background: #F5F6FA;
  min-height: 100vh;
  padding-left: 340px;
  padding-top: 100px;
  padding-right: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar {
  position: fixed;
  width: 300px;
  /*background: #e24d7a;*/
  background: #000000;
  min-height: 450px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s ease;
  padding: 80px 0;
  text-align: center;
  overflow-y: auto;




  box-shadow: 0 0 15px 0 rgba(0,0,0,.05);
  /*width: 100%;*/
  max-width: 260px;
  background: #fff;
  background: linear-gradient(0deg,#000000,#000000);
  /*background: linear-gradient(0deg,#e24d7a,#4d0316);*/

}

.header_wrapper {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 9999;
  box-shadow: 1px 1px 35px 0px #bbb;
  left: 0;
  padding: 10px 20px 5px;
}



.header_logo img {
  max-width: 100px;
}

.header_wrapper>div.bell_icon {
  float: right;
  margin-top: 9px;
}
.header_wrapper>div {
  float: left;
}

.header_search {
  position: relative;
  margin-left: 240px;
  width: 100%;
  max-width: 610px;
}

.header_search .form-group.has-search {
  margin: 0;
}

.header_search .form-control-feedback {
  position: absolute;
  top: 14px;
  left: 7px;
  cursor: pointer;
  color: #A0A5B9;
  font-size: 21px;
}

.header_search input.form-control {
  padding-left: 40px;
  width: 100%;
  background: transparent;
  border: 0;
}
.sidebar .logo {
  padding: 40px 10px;
}

.sidebar .logo p {
  font-size: 18px;
  color: #1E2538;
  margin: 20px 0 0;
}
.sidebar_menu {
  text-align: left;
  padding: 0 10px;
}
.sidebar_menu a {
  color: #e5e7e5;
  font-size: 16px;
}
.sidebar_menu .nav-item {
  padding: 20px 0;
  max-width: 90%;
  margin: 0 auto;
}
.sidebar_menu .nav-item a.active {
  /*color: #1E2538;*/
  color: #fff;

}
.sidebar_menu .nav-item img {
  margin-right: 20px;
  filter: grayscale(1);
  max-width: 24px;
}
.sidebar-item-icon {
  display: inline-block;
  max-width: 40px;
  width: 100%;
}
.sidebar_menu .nav-item a.active img {
  filter: grayscale(0);
}
.sidebar_menu .nav-item a.active img {
  filter: grayscale(0);
}

.sidebar_button {
  margin-top: 30px;
}

.btn-info {
  background: #23A6D7;
  border-color: #23A6D7;
}

.btn {
  font-size: 18px;
  padding: 9px 10px;
  width: 100%;
  max-width: 160px;
}


/* order start */

.body_inner_wrapper {
  background: #fff;
  border-radius: 10px;
}

header.header_content {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_content {
  padding: 20px;
  border-top: 1px solid #dee2e6;
}

.main_content .table thead th {
  border-top: 0;
  color: #9FA6B9;
  /*font-size: 12px;*/
  font-weight: normal;
  white-space: nowrap;
}

.main_content .table td {
  color: #1E2538;
  /*font-size: 13px;*/
}

header.header_content p {
  color: #1E2538;
  font-size: 16px;
  margin: 0;
}

header.header_content button.btn {
  font-size: 12px;
  max-width: 150px;
}

header.header_content button.btn i {
  margin-right: 5px;
}

.action_wrapper button {
  font-size: 12px;
  padding: 5px 6px;
  max-width: none;
  width: auto;
  margin-left: 10px;
}
.action_wrapper {
  white-space: nowrap;
}
.action_wrapper i {
  cursor: pointer;
}
.user_actions {
  cursor: pointer;
}
.action_wrapper>span:nth-child(even) {
  padding: 0 15px;
}

.action_wrapper>span .fa-eye {
  color: #F78F1E;
  font-size: 15px;
}
/* .main_content .table tr {
  cursor: pointer;
} */
.cm_order_view .modal-dialog {
  height: calc(100% - 3.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 640px;
  border-radius: 20px;
}
.cm_order_view * {
}

.cm_order_view .modal-header {
    justify-content: center;
    padding-top: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.cm_order_view .modal-header .modal-title.h4 {
    font-size: 16px;
}

.cm_order_view button.close {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 20px;
    background: #000;
    padding: 0 6px;
    margin: 0;
    color: #fff;
    opacity: 1;
    font-weight: normal;
}

.cm_order_view .modal-header .modal-title.h4 p {
    margin: 0;
}

.cm_order_view .modal-body {
    padding-left: 50px;
    padding-right: 50px;
}

.cm_order_view .modal-body ul {
    padding: 0;
    list-style: none;
    margin: 0;
    color: #9FA6B9;
}

.cm_order_view .modal-body ul li span {
    color: #1E2538;
}

.cm_order_view .modal-body ul li {
    margin: 0 0 10px;
}

.print_button {
    text-align: center;
    border-radius: 10px;
}



.print_button .btn-light .fa-print {
    color: #FC4B4B;
    margin-right: 10px;
}

.order_items {
  color: #9FA6B9;
  font-size: 16px;
  margin: 0 -5px;
}
.order_items table th {
    font-weight: normal;
    padding: 5px;
}

.cm_order_view .modal-footer {
  display: block;
  padding: 0 49px;
}

.footer_btn_wrapper {
  display: flex;
  justify-content: space-between;
}

.cm_order_view .modal-footer .footer_total {
  display: flex;
  justify-content: space-between;
}

.order_items table {
    width: 100%;
}

.order_items table td {
    padding: 5px;
}
.cm_order_view .modal-content {
  padding-bottom: 40px;
}


/* order end */


/* farm start  */

.file_uploader {
  padding: 20px;
  background: #ECEDEE;
  position: relative;
  max-width: 160px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 170px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.file_uploader .custom.custom-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  display: flex;
  height: 100%;
  z-index: 9;

}
.cm_imgupload .custom.custom-file {
  opacity: 1;
  cursor: auto;
}
.cm_imgupload .custom.custom-file img + div {
  display: none;
}
.farm_page .row {
  padding-bottom: 25px;
}


.file_uploader .fa-plus-circle {
  font-size: 50px;
  color: #9FA6B9;
}

.file_uploader .custom-file-input {
  height: 100%;
  cursor: pointer;
}

.file_uploader label.custom-file-label {
  height: 100%;
  cursor: pointer;
}
.fileupload_wraper {
  display: flex;
  /* justify-content: space-between; */
  margin: 0 -10px;
}

.fileupload_wraper .file_uploader {
  margin: 0 10px;
  width: 100%;
}

.video_uploader .file_uploader {
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.video_uploader .file_uploader p {
  text-align: center;
  margin: 20px 0 0;
}
.btn-warning {
  background: #138496;
  border-color: #138496;
  color: #fff;
}
/* farm end  */


.distribution_form .form-group>div {
  float: left;
}

.distribution_form .form-group:after {
  content: "";
  clear: both;
  display: block;
}
.distribution_form>.row {
  margin-bottom: 30px;
}
.distribution_form .form-group {
  margin: 0;
}
.distribution_form p span {
  color: #A0A5B9;
}
.distribution_form>.row p {
  margin: 15px 0 0;
}
img.map {
  width: 100%;
}
.header_btn i {
  font-size: 17px;
}

.header_btn .btn-light {
  background: #fff;
  border: 0;
  margin: 0 20px;
  width: fit-content;
}
header.header_content button:last-of-type {
  width: 100%;
}

.header_btn {
  width: auto;
  justify-content: flex-end;
}
.cm-inventory * {
}

.cm-inventory nav.nav.nav-tabs {
    margin-bottom: 30px;
}

.cm-inventory .nav-tabs .nav-link.active {
    background: transparent;
    border: 0;
    color: #23A6D7;
}

.cm-inventory .nav-tabs .nav-link {
    color: #9FA6B9;
    padding: 10px 40px;
    position: relative;
}

.cm-inventory .card-title.h5 {
    display: flex;
    justify-content: space-between;
}

.cm-inventory .card-text {
    display: flex;
    justify-content: space-between;
}
.cm-inventory .card-title.h5 p {
  color: #9FA6B9;
  margin: 10px 0 0;
}
.cm-inventory .card-title.h5 .custom-control label.custom-control-label {
  font-size: 0;
}

.cm-inventory .card-text p {
  color: #9FA6B9;
}
.text-warning {
  color: #138496 !important;
}

.text-info {
  color: #23A6D7 !important;
}
.inventry .modal-body .row {
  padding: 5px 0;
}
.dlt_item .modal-body {
  text-align: center;
}

.dlt_item .modal-header {
  border: 0;
}

.dlt_item .modal-footer {
  border: 0;
}

.edit_item .modal-header {
  border: 0;
}

.edit_item .modal-footer {
  border: 0;
}


.u_img {
  position: relative;
  width: 120px;
  margin: 0 auto;
}
.u_img span.img_icon {
  position: absolute;
  bottom: 5px;
  right: -16px;
}


.setting_wrapper .nav-pills .nav-link.active, .setting_wrapper .nav-pills .show>.nav-link {
    background: transparent;
    color: #117a8b;
}

.setting_wrapper .nav-pills .nav-link {
    color: #9FA6B9;
    font-size: 24px;
}

.setting_wrapper .nav-item {
    padding: 10px 0;
    border-bottom: 1px solid #F2F1F6;
}

.setting_wrapper .nav-item:last-child {
    border: 0;
}
.setting_wrapper .verify_id .file_uploader {
  max-width: 100%;
  font-size: 26px;
  color: #9FA6B9;
}
.setting_wrapper .file_uploader {
  max-width: 100%;
}
.print_button .btn-light {
  background-color: #FFECEC;
  border-color: #FFECEC;
  font-size: 16px;
  max-width: 120px;
}
.header_btn>.btn {
  margin: 0 10px;
}
.header_btn>.btn {
  margin: 0 10px;
  padding: 9px 18px;
}

.header_btn .print_button .btn {
  margin: 0;
  padding: 9px 32px;
}
.error_msg {
  color: #290707;
}
.text_light {
  color: #9FA6B9;
}
.text_12 {
  font-size: 12px;
}

.text_dark {
  color: #1E2538;
}
.switch_wrapper {
  display: inline-block;
  position: relative;
}
.switch_wrapper input {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  cursor: pointer;
  font-size: 0;
  opacity: 0;
}
.checkbox_swich {
  width: 40px;
  height: 20px;
  background: #fff;
  display: block;
  position: relative;
  border-radius: 40px;
  border: 1px solid #adb5bd;
  transition: all 0.25s;
}
.checkbox_swich:after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  background: #adb5bd;
  border-radius: 100%;
  left: 3px;
  top: 2px;
  transition: all 0.25s;
}
.switch_wrapper input:checked + span {
  background: #007bff;
}
.switch_wrapper input:checked + span:after {
  background: #fff;
  border-radius: 100%;
  left: 22px;
}

.editfarmer_wrapper nav.nav.nav-tabs {
  border: 0;
  margin-bottom: 10px;
}

.editfarmer_wrapper nav.nav.nav-tabs a.nav-item.nav-link {
  font-size: 16px;
  color: #000000;
}

.editfarmer_wrapper nav.nav.nav-tabs a.nav-item.nav-link.active {
  color: #138496 0%;
}
.editfarmer_wrapper {
  border-top: 1px solid #dee2e6;
  padding: 10px 0 0;
}
.sorting::after {
  content: "";
  cursor: pointer;
  font-family: fontawesome;
  margin-left: 10px;
  color: rgba(50, 50, 50, 0.5);
}
.u_img .custom.custom-file {
  position: absolute;
  height: 100%;
  opacity: 0;
}

.u_img .custom.custom-file input.custom-file-input {
  height: 100%;
  position: absolute;
  z-index: 9;
  cursor: pointer;
}

/**loaderrrrrrr**/
.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(181, 181, 181, 0.5);
}

.lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.body_inner_wrapper .header_search {
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 0 10px;
}
header.header_content>* {
  height: max-content;
}


/* 404 start  */
.error {
  color:red;
  /*text-align: center;*/
  width: 100%;
  font-size: 18x;
  padding-bottom: 2px;
  /*padding: 70px 0;*/
}

.error-code {
  color: #234269;
  font-family: 'Fjalla One', sans-serif;
  font-size: 200px;
  line-height: 1;
}
.error-message h2 {
  color: #e34649;
  display: inline-block;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0 15px;
}
#errorboxbody p {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 25px;
  margin-top: 0;
}
.button-home {
  background: linear-gradient(to bottom, #138496 0%, #117a8b 100%);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-family: 'Rubik', sans-serif;
  font-size: 11px;
  font-weight: 400;
  height: 42px;
  letter-spacing: 1px;
  line-height: 42px;
  padding: 0 36px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}
a.button-home:hover {
  text-decoration: none;
  color: #000;
}
/* 404 end */

.width-100 {
  max-width: 100% !important;
}
.max-w-100 {
  max-width: 100%;
}
.cm_checkbox input {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 9;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.cm_checkbox span {
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #000;
  background: #fff;
  margin-right: 10px;
  cursor: pointer;
}
label.cm_checkbox {
  margin: 0;
  position: relative;
}
label {
  margin-bottom: 0;
  vertical-align: middle;
}

.cm_checkbox input + span:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
  z-index: 999;
  opacity: 0;
}

.cm_checkbox input:checked + span:after {
  opacity: 1;
}

span.placeholder {
  color: #8e8e8e;
  margin: 10px 0;
  display: inline-block;
  font-size: 20px;
}
.forgot_wrapper.form-group a {
  color: #fff;
}
.btn-warning {
  color: #fff !important;
}
a.text-light:focus, a.text-light:hover {
  color: #fff !important;
}
.ant-select .ant-select-selector {
  background-color: #ecedee;
  padding: 8px 20px;
  border: 0;
}

.ant-select.form-control {
  padding: 0px;
}

.page_heading {
  border-top: 1px solid #dee2e6;
  padding: 20px;
}

.page_heading h6 {
  margin: 0;
  font-size: 18px;
}
.u_img input.cm_hide_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}
input[type=checkbox], /* FF, IE7+, chrome (except button) */
input[type=checkbox]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}
.custom-switch label {
  cursor: pointer;
}
button.ant-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 20px;
  background: #000;
  padding: 5px 6px;
  margin: 0;
  color: #fff;
  opacity: 1;
  font-weight: bold;
}

span.ant-modal-close-x {
  width: auto;
  height: auto;
  line-height: 0.5;
}

.anticon svg {
  font-weight: 400;
  font-size: 14px;
}

.ant-modal-body {
  text-align: center;
}

.ant-modal-body p {
  font-size: 24px;
  font-family: FuturaPT, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1E2538;
  font-weight: 300;
}

.ant-modal-footer {
  text-align: center;
  padding: 20px 16px;
}

.ant-modal-content {
  padding-top: 30px;
}
.ant-modal-close:focus, .ant-modal-close:hover {
  outline: none;
  color: #fff;
}
.fa-trash-alt {
  cursor: pointer;
}
.cm_order_view.dlt_item .modal-body p {
  font-size: 24px;
}
button:focus {
  outline: none !important;
}

.upload-onicon {
  position: absolute;
  padding: 10px;
  top: 0;
  right: 50px;
}
.upload-onicon input.custom-file-input {
  z-index: 9;
}
.upload-onicon .custom.custom-file {
  z-index: 9;
}
.dwnload-icon {
  color: #fff;
  position: absolute;
  right: 0px;
  top: 0;
  padding: 10px;
  cursor: pointer;
  z-index: 9;
}
.imgas_bg {
  position: absolute;
  width: 100%;
  height: 90%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* ---------------------pagination------------------- */
ul.pagination li a , ul.pagination li.disabled a , ul.pagination li.disabled a:hover{
  color: #0a0a0a;
  background: #ffffff;
  border: 1px solid #ccc;
}
ul.pagination li:first-child , ul.pagination li:last-child {
  display: none;
}

ul.pagination li {
  text-align: center;
}
ul.pagination li:hover a, ul.pagination li.active a {
  background: #139bf1;
  color: #fff;
}
ul.pagination li a {
  border-radius: 2px;
}
ul.pagination li {
  margin-right: 10px;
  background: white;
}
ul.pagination li.nextClass a {
  background: #fff;
  border-color: #139bf1;
  color: #139bf1;
}

ul.pagination li.prevClass a {
  background: #fff;
  border-color: #139bf1;
  color: #139bf1;
}

ul.pagination li.prevClass.disabled a,
ul.pagination li.nextClass.disabled a {
  color: #0a0a0a !important;
  background: #ffffff !important;
  border: 1px solid #ccc !important;
  cursor: not-allowed;
}

.cm-pagination ul.pagination li a {
  padding: 0 6px 1px;
}

/*ul.pagination li.disabled a {*/
/*    color: #9FA6B9;*/
/*}*/

/*ul.pagination li a {*/
/*    font-size: 20px;*/
/*    color: #9FA6B9;*/
/*}*/

/*ul.pagination li {*/
/*    margin: 0 2px;*/
/*}*/

/*ul.pagination li a.pagination_round {*/
/*    padding: 6px 10px 5px 7px;*/
/*    background: #F78F1E;*/
/*    color: #fff;*/
/*    border-radius: 8px;*/
/*    display: inline-block;*/
/*    line-height: 1;*/
/*}*/

/*ul.pagination li a.pagination_round.right {*/
/*    padding: 6px 7px 5px 10px;*/
/*}*/

/*ul.pagination li.active a {*/
/*    color: #f78f1e;*/
/*    padding: 0 5px;*/
/*    background: #eee;*/
/*    border-radius: 5px;*/
/*}*/
/*ul.pagination li.disabled a.pagination_round {*/
/*    background: #aba6b9;*/
/*}*/
.cm-inventory .card>.inventry_img_wrapper {
  max-height: 220px;
  min-height: 220px;
  position: relative;
}
.cm-inventory .card>.inventry_img_wrapper img.card-img-top {
  max-height: 220px;
  min-height: 220px;
  object-fit: cover;
}
.cm-inventory .edit_dlt {
  position: absolute;
  top: 10px;
  right: 0;
  color: #fff;
  font-size: 19px;
  z-index: 9;
  opacity: 0;
}
.cm-inventory .edit_dlt span {
  margin: 0 8px;
  cursor: pointer;
}
.cm-inventory .card>.inventry_img_wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
}
.cm-inventory .card:hover >.inventry_img_wrapper:after {
  opacity: 0.4;
}
.cm-inventory .card:hover >.inventry_img_wrapper .edit_dlt {
  opacity: 1;
}
.cm-inventory .nav-tabs .nav-link {
  color: #9FA6B9;
  padding: 10px 40px;
  position: relative;
  border: 0;
}
.cm-inventory .nav-tabs .nav-link.active {
  background: transparent;
  border: 0;
  color: #23A6D7;
}
.cm-inventory .nav-tabs .nav-link.active:after {
  opacity: 1;
}

.cm-inventory .nav-tabs .nav-link:after {
  content: "";
  display: block;
  height: 4px;
  width: 100%;
  background: #23A6D7;
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  opacity: 0;
}
.deleteImage{
  z-index: 9;
}
.cm_errordoc {
  text-align: center;
  width: 100%;
  padding: 70px 0;
}
.cm_error {
  width: 100%;
  text-align: center;
}

.cm_error-code {
  color: #234269;
  font-family: 'Fjalla One', sans-serif;
  font-size: 200px;
  line-height: 1;
}
.cm_error-message h2 {
  color: #444;
  display: inline-block;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0 15px;
}
a,button {
  outline: none !important;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none;
}
.activeheading h6, .activeheading {
  color: #138496 0%;
}
.footer_btn_wrapper.button_space {
  justify-content: space-evenly;
}
.cm_edit_item {
  position: relative;
}
.c-pointer {
  cursor: pointer;
}
.cm_edit_item .fa-plus-circle {
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 15px;
}
.cm-modal-height .modal-dialog {
  height: auto !important;
}
.cm_order_view .modal-dialog {
  height: calc(100% - 3.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 640px;
  border-radius: 20px;
}
.cm_order_view * {
}

.cm_order_view .modal-header {
    justify-content: center;
    padding-top: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.cm_order_view .modal-header .modal-title.h4 {
    font-size: 16px;
}

.cm_order_view button.close {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 20px;
    background: #000;
    padding: 0 6px;
    margin: 0;
    color: #fff;
    opacity: 1;
    font-weight: normal;
}

.cm_order_view .modal-header .modal-title.h4 p {
    margin: 0;
}

.cm_order_view .modal-body {
    padding-left: 50px;
    padding-right: 50px;
}

.cm_order_view .modal-body ul {
    padding: 0;
    list-style: none;
    margin: 0;
    color: #9FA6B9;
}

.cm_order_view .modal-body .row {
    padding: 10px 0;
}

.cm_order_view .modal-body ul li span {
    color: #1E2538;
}

.cm_order_view .modal-body ul li {
    margin: 0 0 10px;
}
.edit-distribution .accordion>.card:first-child {
  border-top: 0;
}
.cm_placeorder_wrapper .accordion>.card>.card-header {
  background: #fff;
  padding-left: 0;
  padding-right: 0;
}
.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.cm_placeorder_wrapper button.btn.btn-link {
  text-decoration: none;
  padding-left: 0;
  padding-right: 0;
}
.cm_placeorder_wrapper .card-header button {
  max-width: 100%;
}
.cm_placeorder_wrapper .card-header .cm_time_wrapper {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 13px;
}
.cm_time_wrapper span {
  text-decoration: none;
}
label.cm_checkbox {
  margin: 0;
  position: relative;
}
.cm_checkbox input {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 9;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.cm_checkbox span {
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #000;
  background: #fff;
  margin-right: 10px;
  cursor: pointer;
}
.cm_checkbox input + span:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  content: "";
  position: absolute;
  z-index: 999;
  opacity: 0;
}
.cm_checkbox input:checked + span:after {
  opacity: 1;
}
.cm_order_view .card {
  border-left: 0;
  border-right: 0;
}
.time_itemwrapper .accordian_wrapper {
    margin-bottom: 20px;
}

.accordian_wrapper {
    display: flex;
}
.accordian_wrapper select.form-control {
    background: transparent;
    border: 1px solid #000;
    box-shadow: none;
}

.accordian_wrapper .form-control {
    font-size: 12px;
}
.c-pnt {
  cursor: pointer;
}
.cm_order_view .modal-footer {
  display: block;
  padding: 0 49px;
  border-top: 0;
}
.btn_nospace {
  justify-content: space-around;
}
button#categories {
  padding: 0;
  background: transparent;
  color: #9FA6B9;
  font-size: 12px;
  font-weight: normal;
  border: 0;
  outline: none;
  box-shadow: none;
}
.overflow_remove {
  overflow-x: visible;
}


.modal-dialog {
  height: auto !important;
}

.fade.modal-backdrop.show {
  z-index: 9999;
}

.modal {
  z-index: 9999;
}
.rowspace.cm_order_view .modal-body .row {
  padding: 5px 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.dlt_item .modal-dialog {
  max-width: 540px;
}
img.pos_abs {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overflow_remove {
  overflow-x: visible;
}
.video_upload {
  height: 170px;
}
.videothumb {
  padding: 0;
}

.videothumb img {
  height: 170px;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.videothumb .react-thumbnail-generator {
  width: 100%;
}
.farmerlogowrapper {
  /*display: none;*/
  font-size: 30px;
}
.farmerlogowrapper img {
  margin-bottom: 20px;

}
.tableheading-category button {
  padding: 0;
  border-top: 0;
  color: #9FA6B9 !important;
  font-size: 12px;
  font-weight: normal;
  background: transparent !important;
  border: none;
  box-shadow: none !important;
  margin: 0 !important;
}
.textboxcolor{
  background-color: #3C3C3C !important;
}
.textboxcol{
  /*color:#E0067A;*/
  border:#E0067A ;
  outline: #E0067A;
  background-color:#E0067A !important;
}
.ws-nowrap {
  white-space: nowrap;
}
.file_uploader_manager .file_uploader {
  max-width: 260px;
  margin-left: 0 !important;
}
.header_search {
  margin: 0 15px;
  max-width: 610px;
}
.header_search .form-control-feedback {
  font-size: 15px;
  top: 17px;
}

.header_search {
  /*position: relative;*/
  /*margin-left: 240px;*/
  width: 100%;
  max-width: 610px;
}

.header_search input.form-control {
  padding-left: 30px;
  margin: 0;
}
ul.pagination li a, ul.pagination li.disabled a, ul.pagination li.disabled a:hover {
  padding: 0 4px;
}
.cm_phnnumber input.form-control {
  font-size: 20px;
  background: #ECEDEE;
  border-color: #ECEDEE;
  padding: 10px 20px;
  height: auto;
  width: 100%;
  padding-left: 50px;
  font-family: FuturaPT, sans-serif;
  /* color: #495057; */
  color: #6c757d;
}
.cm_phnnumber input.form-control::placeholder {
  color: #6c757d;
}
.form-control:focus::placeholder {
  color: #495057;
}

.cm_phnnumber .react-tel-input .flag-dropdown {
  border: 0;
  border-right: 1px solid #cacaca;
  background: #ECEDEE;
  padding: 0 3px;
}
.cm_phnnumber .react-tel-input .flag-dropdown:hover .selected-flag, 
.cm_phnnumber .react-tel-input .flag-dropdown:focus .selected-flag,
.cm_phnnumber  .react-tel-input .flag-dropdown.open .selected-flag {
  background: #ECEDEE;
}

.recipe .modal-dialog {
  max-width: 840px;
}

.col-md-itemwidth_inventry {
  max-width: 33%;
  flex: 0 0 33%;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
  z-index: 1;
  top: 0;
  left: 0;
}

.fa-pen.text-light {
  position: relative;
  z-index: 2;
}
select.form-control, span.form-control {
  color: #747c84;
}
.logoroundimg{
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.uploaddoc {
  flex-direction: column;
  max-width: 100%;
}
.order-detailsmodal .modal-dialog {
  max-width: 870px;
}
.order-detailsmodal table th {
  padding: 8px;
}
.order-detailsmodal .footer_btn_wrapper {
  justify-content: space-evenly;
}
.uploaddoc.superadmin .upload-onicon {
  right: 0;
}
.changepassword {
  margin: 20px 0;
}
.setting_wrapper .nav-link {
  display: inline-block;
}
.cm_phnnumber input.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.footerloginbg {
  background-repeat: no-repeat;
  background-position: center bottom -30px;
  background-size: cover;
  min-height: 250px;
}

.cm-pagination {
  margin: 20px 0 0;
}

.mobile-trigger-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #138496 0%;
  padding: 10px 17px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  z-index: 99999;
  color: #fff;
}
.mobile-trigger-wrapper {
  display: none;
}
.login_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: calc(100vh - 360px);
}
.login_wrapper>.row {
  width: 100%;
}
.login_page .container>h1 {
  font-size: initial;
  padding-top: 10px;
}
.fileupload_wraper .file_uploader {
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
  line-height: 130px;
  background-repeat: no-repeat;
  background-position: center;
}
.fileupload_wraper {
  display: block;
}
.cm_edit_item span.form-control {
  padding-right: 30px;
}
.edit-distribution .modal-body .btn {
  margin: 0 !important;
}
.dltimg {
  z-index: 99;
  color: #fff;
  position: relative;
  margin: 0 3px;
}
div#jsx-template {
  overflow: auto;
  display: none;
}

div#divIdToPrint {
  width: 1070px;
}
.pdfwrapper {
  margin: 0 auto;
}

.tablecenter {
  margin: 0 auto;
}
.invoicewrapper {
  margin: 0 auto;
  overflow: auto;
}
.inventry .file_uploader {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.discription-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*-webkit-line-clamp: 2;*/
  -webkit-box-orient: vertical;
   max-height: 45px;
  word-wrap: break-word;
  max-width: 550px;
}
.file_uploader.documentpdf {
  width: 100%;
  max-width: initial;
  height: 150px;
  padding: 10px 37px 10px 10px;
}
.documentpdf .dwnload-icon {
  background: #fff;
  border-radius: 50px;
  padding: 7px 10px;
  color: #000;
  right: 0;
  font-size: 12px;
}

.documentpdf .dwnload-icon a {
  color: #000;
}
span.mr-3.d-inline-block.text_light.text_12 {
  white-space: nowrap;
}
.w-nowrap {
  white-space: nowrap;
}
.cm_time_wrapper {
  display: inline-block;
  white-space: nowrap;
}
div#outline {
  text-align: center;
}
.form-control.discriptionwrapper {
  min-height: 40px;
  max-height: 350px;
  overflow-x: auto;
}
.discription-wrapper p {
  margin: 0;
}
.discription-wrapper * {
  font-size: 12px;
  display: inline;
  margin: 0 3px;
  padding: 0;
}

.discription-wrapper input[type="checkbox"] {
  display: none;
}
.header_search_custom {
  margin: 0px;
  max-width: 100%;
}
.header_search_custom .has-search {
  border: 1px solid rgba(221, 221, 221, 0.48);
  background: #fff;
  border-radius: 5px;
}
.header_search_custom .form-control-feedback {
  left: 40px;
}
.header_search_custom .form-control {
  padding-left: 50px !important;
}
.count-list {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(221, 221, 221, 0.48);
}
.count-list h6 {
  color: #333;
  font-size:20px
}
.user-icon {
  height: 70px;
  width: 70px;
  background: rgba(238, 238, 238, 0.26);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sizeCircle{
  font-size: 30px;
}
.sizeCircleDelete{
  font-size: 26px;
}
.user-icon svg {
  width: 40px;
}
.icon-blue {
  background: #24a6d7;
}
.icon-grey{
  background: #f5f6fa;

}
.icon-pink {
  background: #e24d7a;
}
.icon-black {
  background: #a3a9a6;
}
.icon-blue svg, .icon-orange svg, .icon-pink svg,.icon-pink svg,.icon-black svg {
  fill: #fff;
}












/* media start  */

@media (min-width: 1350px){
  .col-md-itemwidth_inventry {
    max-width: 25%;
    flex: 0 0 25%;
  }
}
.ml-auto, .mx-auto {
  width: 290px;
  margin-left: auto !important;
}
.login_wrapper{
  max-width: 540px;
  background-color: #000000;
  border-radius: 25px;
}
.wrapforgot{

  max-width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
  max-height: 834px;
  /*margin-left: 28%;*/


}
.wrp{}
@media(max-height : 700px ){
  .login_wrapper {
    height: calc(100vh - 240px);
  }
  /* .footerloginbg {
    background-size: contain;
  } */
  .login_wrapper img {
    max-width: 70px;
  }

  .login_wrapper .form-control, .login_wrapper button.btn {
      margin-bottom: 10px;
      padding: 6px 20px;
      font-size: 15px;
  }
  .login_page .navbar-brand img {
    max-width: 50px;
  }
  .footerloginbg {
    min-height: 176px;
  }
}




@media (min-width : 1350px){
  .itemwidth_inventry {
    max-width: 25%;
    flex: 0 0 25%;
  }

  
}

@media (max-width: 1400px){
  .header_search {
    max-width: max-content;
}
  .d-flex.text-nowrap.header_btn {
    display: block !important;
    white-space: normal !important;
    padding-left: 20px;
  }
  header.header_content button.btn {
    width: auto;
    margin: 0 10px 10px 0;
    
  }
  .print_button {
    display: inline;
  }
  .overflow_remove {
    overflow-x: auto;
  }
  header.header_content button.btn {
    max-width: initial;
  } 
}

/* responisve  */
@media (max-width: 1200px){
  .col-md-itemwidth_inventry {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .cm-inventory .nav-tabs .nav-link {
    padding: 10px 20px;
  }
  .card-title.h5 {
    font-size: 15px;
  }
  .form-control {
    padding: 10px 10px;
    font-size: 17px;
  }
  div#divIdToPrint header.header_content {
    display: flex;
  }
  
  .header_btn .print_button .btn {
    padding: 9px 12px;
  }
  .header_btn {
    margin-top: 10px;
  }
  .rounded-circle {
    height: 99px;
    width: 99px;
  }
  .rounded-circle1{
    height: 150px;
    width: 150px;
    object-fit: cover
  }
  /*.rounded-circle {*/
  /*  border-radius: 50%!important;*/
  /*  width: 150px;*/
  /*  height: 150px;*/
  /*  object-fit: cover;*/
  /*}*/
  .sidebar_menu .nav-item {
    max-width: 100%;
  }
  .sidebar_menu .nav-item img {
    max-width: 24px;
  }
  .sidebar-item-icon {
    max-width: 30px;
    vertical-align: middle;
  }
  .sidebar_menu a {
    font-size: 14px;
  }
  .sidebar {
    width: 300px;
  }
  .body_container {
    padding-left: 280px;
    padding-right: 20px;
    padding-left: 240px;

  }
  .action_wrapper {
    width: 70px;
  }
  .header_wrapper {
    display: flex;
    padding: 10px 69px 21px 20px;
    align-items: center;
  }
  .main_content .table thead th {
    white-space: nowrap;
  }
  .main_content .table-responsive.overflow_remove {
    min-height: 170px;
  }

}
@media (max-width: 1000px){
  .form-control, .cm_phnnumber input.form-control {
    padding: 5px 8px;
  }
  .d-flex.text-nowrap.header_btn {
    padding-left: 0;
  }
  header.header_content {
    display: block;
  }

  .itemwidth_inventry {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .setting_wrapper .nav-pills .nav-link {
    color: #9FA6B9;
    font-size: 18px;
  }
  .btn {
    font-size: 15px;
  }
}

@media (max-width: 992px){
  .error_msg {
    color: #290707;
    font-size: 14px;
  }
  .cm_edit_item .fa-plus-circle {
    top: 10px;
  }
  .mobile-trigger-wrapper {
    display: block;
  }
  .edit-distribution.modal label {
    margin: 0;
  }
  .cmbtngrp {
    text-align: center !important;
  }
  .tab-content {
    padding: 10px;
  }
  .header_btn .print_button .btn {
    margin: 0 0 10px;
  }
  .fileupload_wraper .file_uploader {
    max-width: 150px;
    min-height: 110px;
    line-height: 110px;
}
  label {
    margin-bottom: 10px;
  }
  .adddistribution .distribution_form>.row p {
    margin-bottom: 10px;
  }
  .sidebar_button .btn {
    margin-right: 10px;
}
  .distribution_form>.row p {
    margin: 0 0 0;
}
  .time_itemwrapper .accordian_wrapper span.mx-5 {
    margin: 0 6px !important;
  }
  .mb-sm-3 {
    margin-bottom: 20px !important;
}
  .distribution_form>.row {
    margin-bottom: 0;
  }
  .btn {
      margin-bottom: 10px;
  }
  .form-control {
      overflow: hidden;
  }
  .mobilebtn-setting {
    margin: 20px 0 30px;
}
  .itemwidth_inventry {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .sidebar {
    display: none;
    height: max-content;
    right: 70px;
    left: auto;
    top: 10px;
    padding: 20px 0;
    background: #f9f9f9;
    min-height: auto;
    z-index: 99999;
  }
  .sidebar.open {
    display: block;
  }
  .sidebar .logo {
      padding: 0;
      display: none;
  }

  .sidebar_menu {
      width: 100%;
      justify-content: space-between;
      padding-left: 0;
  }

  .sidebar_menu .nav-item {
      padding: 10px 10px;
  }

  .sidebar_menu .nav-item img {
      display: block;
      margin: 0 auto;
  }

  .sidebar-item-icon {
      max-width: 40px;
  }

  .sidebar_button {
    margin: 0;
    text-align: left;
    padding: 10px 26px;
  }

  .sidebar_button button {
      padding: 3px 7px;
      width: auto;
      font-size: 12px;
  }


  .body_container {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 30px;
  }
  .cm-inventory .nav-tabs .nav-link {
    padding: 10px 8px;
    font-size: 11px;
  }

  .main_content {
      padding: 10px;
  }
  .cm_order_view .modal-body {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal-open .modal {
      padding: 10px !important;
  }

  .footer_btn_wrapper button {
      margin: 0 10px;
  }

  .cm_order_view .modal-footer {
      padding: 0 0;
  }
  .body_inner_wrapper .header_search {
    margin: 10px 0;
  }
  .header_search {
    margin: 0 15px;
    max-width: 78%;
  }

  .farm_page .row {
    padding: 0 0 10px;
  }

  .modal-content .button.btn {
    font-size: 16px;
    padding: 6px 8px;
    width: 100%;
    max-width: 110px;
  }
  .button.btn {
    font-size: 16px;
    padding: 6px 8px;
  }
  .modal {
    z-index: 99999;
  }
  .fade.modal-backdrop.show {
    z-index: 99999;
  }
}



@media (max-width: 767px){
  .form-control {
    margin-bottom: 20px;
  }
  .invoicewrapper button.btn {
    margin-left: 0 !important;
  }
  .cm_order_view .modal-body .row {
    padding: 0;
  } 

  .btn {
    margin: 10px !important;
  }
  .col-md-itemwidth_inventry {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (max-width: 1400px){
  .header_btn .print_button .btn {
    margin: 0 0 10px;
  }
  .d-flex.text-nowrap.header_btn {
    display: block !important;
    white-space: normal !important;
    padding-left: 20px;
  }
  header.header_content button.btn {
    width: auto;
    margin: 0 10px 10px 0;

  }
  .print_button {
    display: inline;
  }
  .overflow_remove {
    overflow-x: auto;
  }
  .header_search {
    max-width: max-content;
  }
}


@media (max-width: 567px){
  .page_heading h6 {
    font-size: 14px;
  }
  .cm-inventory .card>.inventry_img_wrapper {
    max-height: 100%;
    min-height: 100%;
  }
  .cm-inventory .card>.inventry_img_wrapper img.card-img-top {
    max-height: 100%;
    min-height: 100%;
  }
  .col-md-itemwidth_inventry {
    max-width: 100%;
    flex: 0 0 100%;
  }


  .header_search {
    margin: 0 15px;
    max-width: 610px;
  }
  .header_search {
    margin: 0 15px;
    max-width: 610px;
  }
  .header_search_custom input {
    border: 1px solid #dddd !important;
    background: #fff !important;
    border-radius: 10px;
  }
  .header_search_custom .fa {
    font-size: 15px;
    top: 19px;
    left: 20px;
  }
  .header_search_custom {
    max-width: 350px;
    margin: 0px 0px 15px 0px;
  }
  .header_search .form-group.has-search {
    margin: 0;
  }
  .header_search .form-control-feedback {
    position: absolute;
    top: 14px;
    left: 7px;
    cursor: pointer;
    color: #A0A5B9;
    font-size: 21px;
  }
  .header_search input.form-control {
    padding-left: 40px;
    width: 100%;
    background: transparent;
    border: 0;
    box-shadow: none;
  }

  .setting_wrapper .verify_id .file_uploader {
    font-size: 19px;
  }
  .fileupload_wraper .file_uploader {
    max-width: 130px;
    min-height: 100px;
    line-height: 60px;
  }
  .itemwidth_inventry {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .sidebar_menu a {
    /* font-size: 10px; */
  }

  .sidebar_menu .nav-item img {
    max-width: 16px;
  }

  .sidebar_menu .nav-item {
    padding: 5px 5px;
  }


}
/*.............*/

.bg-img{
  background: url('assets/img/background.png');
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.content{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  text-align: center;
  padding: 60px 32px;
  width: 550px;
  transform: translate(-50%,-50%);
  background: #000;
  border-radius: 30px;
  box-shadow: -1px 4px 28px 0px rgba(0,0,0,0.75);
}
.content header{
  color: white;
  font-size: 33px;
  font-weight: 600;
  margin: 0 0 35px 0;
  font-family: 'Montserrat',sans-serif;
}
.field{
  border-radius: 10px;
  position: relative;
  height: 45px;
  width: 100%;
  display: flex;
  background: rgba(255,255,255,0.94);
}
.field span{
  color: #222;
  width: 40px;
  line-height: 45px;
}
.field input{
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: #222;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Poppins',sans-serif;
}
.space{
  margin-top: 16px;
}
/*.show{*/
/*  position: absolute;*/
/*  right: 13px;*/
/*  font-size: 13px;*/
/*  font-weight: 700;*/
/*  color: #222;*/
/*  display: none;*/
/*  cursor: pointer;*/
/*  font-family: 'Montserrat',sans-serif;*/
/*}*/

.show {
  /* position: absolute; */
  /* right: 13px; */
  font-size: 13px;
  font-weight: 700;
  color: #222;
  display: none;
  cursor: pointer;
  font-family: 'Montserrat',sans-serif;
}
.pass-key:valid ~ .show{
  display: block;
  padding-right: 50px;
}
.pass{
  text-align: left;
  margin: 10px 0;
}
.pass a{
  color: white;
  text-decoration: none;
  font-family: 'Poppins',sans-serif;
}
.pass:hover a{
  text-decoration: underline;
}
.field input[type="submit"]{
 border-radius: 5px;
  border:none;
  background: #E0067A !important;
  color: white;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Montserrat',sans-serif;
}

.star-ratings-css {
  unicode-bidi: bidi-override;
  color: #c5c5c5;
  font-size: 25px;
  height: 25px;
  width: 100px;
  margin: 0 auto;
  position: relative;
  padding: 0;
  text-shadow: 0px 1px 0 #a2a2a2}

.star-ratings-css-top {
   color: #e7711b;
   padding: 0;
   position: absolute;
   z-index: 1;
   display: block;
   top: 0;
   left: 0;
   overflow: hidden;
 }
.star-ratings-css-bottom {
   padding: 0;
   display: block;
   z-index: 0;

}


